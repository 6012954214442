import React, { useState, useEffect } from 'react';

const FoodSupplements = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(16999);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 16999   
  };

  const capacityMapping = {
    "1": ''  
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(900);
      setDisplayPrice(900);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(7999);
      setDisplayPrice(7999);
    }
    if (capacity == 3) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 4) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection('');
    setSelectedPrice(16999);
    setSelectedYears(1);
  }, []);

  return (
    <div className="turnoverDisplay">
      <div className="container bg-white p-3 text-justify mb-3">
        <h6 className="font-bold">Food or Health Supplements and Nutraceuticals etc.:</h6>
        <span>As per Food Safety and Standards (Food or Health Supplements, Nutraceuticals, Foods for Special Dietary Uses, Foods for Special Medical Purpose, Functional Foods, and Novel Food) Regulations, 2016.</span>
      </div>

      {/* Hidden section with radio button for turnover selection */}
      <div className="hidden">
        <label className="font-bold" id="exp">Select Turnover <span className="text-red-600">*</span></label>
        <div className="form-group">
          <input
            type="radio"
            name="turnover"
            
            value="Turnover Above 20CR"
          />
          <label className="custom">Turnover Above 20CR</label>
          <div id="vegi1" style={{ fontSize: '12px' }}></div>
        </div>
      </div>

      {/* Table section */}
      <table className="table-style">
        <tbody>
          <tr>
            <td className="cell-style">
              <span className="text-black font-bold text-lg pricing_displayx"></span>
              <span className="customx">Default Certificate Validity : 1 Year</span>
            </td>
            <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  Applicable Price ₹16999/- <br /> Including Taxes & Fees
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FoodSupplements;
