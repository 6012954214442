import React from 'react';

const WarrantiesAndRefunds = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Fssai Registration Warranties and Refunds</h1>
      <p className="mb-4">
        <strong>We follow a NO REFUND POLICY</strong>
      </p>
      <p className="mb-4">
        Exception to above: Where client requests for cancellation / refund of fees within 24 hours of making payment or processing of his application by our expert team, whichever is earlier, only in such case refund claims will be entertained.
      </p>
      <p className="mb-4">
        In such cases also, refund amount will not exceed 50% of the amount paid by the client.
      </p>
      <p className="mb-4">
        Note: As per our Company's Policy Refund determination and initiation of refund takes 12 - 15 Working Days. After Refund is initiated bank takes 5 - 7 working days for credit of the amount to the client's bank account.
      </p>
      <p className="mb-4">
        Submission of an application on our website by the client is in itself is a proof that client has agreed to all the Terms & Conditions (including Refund Policy) of our website.
      </p>
      <p className="mb-4">
        Decision on refunds taken by our company shall be final & binding upon the client.
      </p>
      <p className="mb-4">
        For Clarification of Doubts, Few situations have been enumerated where NO Refunds will be Allowed:
      </p>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-4">
          Cases of Non Delivery of Services
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-4">
              Any deficiency in document or incomplete documents, or Wrong Documents (For Ex: Valid Water Testing Report from NABL Lab Not submitted, Valid Import Export Licence not provided, Prescription uploaded in place of Medical Fitness report).
            </li>
            <li className="mb-4">
              Any wrong information provided in application (For Ex: Wrong Turnover mentioned - Client’s Turnover is more than 12 Lacs, But client has suppressed that fact & mentioned lesser Turnover in application, Physical Shop does not exist at the given address).
            </li>
            <li className="mb-4">
              Wrong Application submitted (applied for Basic Registration instead of State Licence, FSSAI Laws required client to apply for State / Central Licence but client had applied for Basic Registration).
            </li>
            <li className="mb-4">
              Not sharing of OTP (ONE TIME PASSWORD) – OTPS are sent on the client’s Registered Email Address and / Mobile Number to ensure authenticity of the Applicant. If any client refuses to share OTP Sent on the Email / Mobile that will make it impossible for us to deliver services. Such Cases will be treated as NON Cooperation from the client & NO Refunds will be allowed in such cases.
            </li>
            <li className="mb-4">
              Any application is rejected by the competent authority. Applications are Rejected by the proper office only where:
              <ul className="list-disc list-inside ml-6">
                <li className="mb-2">Client has not complied with requirement under FSSAI Laws.</li>
                <li className="mb-2">Client does not meet Registration Requirements.</li>
                <li className="mb-2">Misinformation provided by the client in the application.</li>
                <li className="mb-2">Where Food officer is in has some information / visit report in the possession of Food Officer.</li>
                <li className="mb-2">Where wrong application has been applied by the client.</li>
                <li className="mb-2">Where client had applied for any Licence in the past & the same was declined by the Officer.</li>
                <li className="mb-2">Where client had, in the past made some contravention under FSSAI Laws.</li>
                <li className="mb-2">And many other situations which arise from time to time.</li>
              </ul>
              In case of Rejected Applications, it must be understood that there is NO Lack of Performance on our Account. Filing of applications are done by us according to the details / documents provided by the client but applications are rejected by officer for any of the above mentioned reasons. None of the reasons are attributable to us. Thus the company reserves the right for NO REFUND, in case of Rejected Applications.
            </li>
            <li className="mb-4">
              Any other shortcomings or non cooperation from client side which renders delivery of services impossible on our end shall not be treated as 'failure of delivery of services from our end' rather such cases will be treated as NON Cooperation from the client & NO Refunds will be allowed in such cases.
            </li>
          </ol>
        </li>
        <li className="mb-4">
          Cases Where Delivery Of Certificates Is Delayed
          <p className="mb-4">
            It shall be noted that we are solely responsible for filling your applications with FSSAI Department. Processing of the application and generation of final certificate is at the discretion of the proper officer.
          </p>
          <p className="mb-4">
            In general Turnaround time (TAT) For generation of final certificate is given below:
          </p>
          <ul className="list-disc list-inside ml-6">
            <li className="mb-2">Basic Registration (25 - 40 Working Days *)</li>
            <li className="mb-2">State Licence (30 - 60 Working Days *)</li>
            <li className="mb-2">Central Licence (45 - 60 Working Days *)</li>
          </ul>
          <p className="mb-4">
            The Above Turnaround Time is indicative in nature. At times certificate generation may take more than normal time. No refunds will be allowed on the basis of claims made for delay in delivery.
            Working days excludes all Saturdays, Sundays and all bank holidays.
          </p>
        </li>
      </ol>
      <p className="mb-4">
        Note: By submitting his application on this website, client provides his free consent and all terms & conditions (including refund policy) is accepted by the client.
      </p>
    </div>

  );
};

export default WarrantiesAndRefunds;
