import React from 'react';
import { useHistory } from 'react-router-dom';

const ThankYou = () => {

  const handleGoHome = () => {
    window.location.href='/';
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-green-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-green-500">Application Submitted Successfully!</h1>
        <p className="mt-4 text-lg text-gray-700">
          Thank you your application is submitted successfully. We will revert back you soon.
        </p>
        <div className="mt-6">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600"
            onClick={handleGoHome}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
