import React from 'react';

function AboutUs() {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">About Us - foodlicenseonline.in</h1>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Why Should You Choose Us:</h2>
          <p className="mb-4">Choosing https://foodlicenseonline.in as your FSSAI-registered private consultant business is a sound selection for a variety of reasons. With a team of seasoned specialists, we provide expert guidance and help throughout the FSSAI registration procedure, assuring a seamless and hassle-free experience for those in the food industry. We have an in-depth understanding of the registration requirements and processes, which guarantees that submissions are accurate and timely. Furthermore, our user-friendly approach and timely customer assistance make the entire process simple and efficient for business owners. By using foodlicenseonline.in, you can depend on their knowledge to guide you through the complexity of FSSAI registration, enabling you to confidently focus on building your business.</p>
          <p className="mb-4">THE FOODLICENSEONLINE.IN WEBSITE IS A PROPERTY OF Knowbility Consultants, PROVIDING B2B CONSULTANCY SERVICES.</p>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Contact Us:</h2>
          <p className="mb-4">Email: <a href="mailto:unitedconsultancy051@gmail.com">unitedconsultancy051@gmail.com</a></p>
          <p className="mb-4">Address: Plot no 20, Dinkar Society, Mangalwar Peth ,Madhavnagar, Sangli. Pin:416406</p>
        </div>
        <div>
          <p>The owner is registered and approved as a Authorised Food Licence Application Filing Service Provider for Food Licensing Services under FSSAI.</p>
        </div>
      </div>
    </div>

  );
}

export default AboutUs;
