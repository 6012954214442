import React from 'react';

function TermsOfService() {
  return (
<div className="bg-white py-12">
  <div className="container mx-auto px-4">
    <h1 className="text-3xl font-bold mb-8">FSSAI Registration Terms of Service</h1>

    <h2 className="text-xl font-semibold mb-4">Introduction</h2>
    <p className="mb-6">This Website Standard Terms and Conditions written on this web page shall manage your use of our website, accessible at <a href="https://foodlicenseonline.in/">https://foodlicenseonline.in/</a></p>
    <p className="mb-6">These Terms will be applied fully and affect your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
    <p className="mb-6">Minors or people below 18 years old are not allowed to use this Website.</p>

    <h2 className="text-xl font-semibold mb-4">DND Communication</h2>
    <p className="mb-6">The user will be required to provide a valid phone number at the time of using the service through the platform. By registering the user's phone number with us, the user gives consent to be contacted by us via phone call. By providing phone numbers to us, the client gives us an irrevocable permission to call & such permission shall override DND rules.</p>

    <h2 className="text-xl font-semibold mb-4">Intellectual Property Rights</h2>
    <p className="mb-6">Other than the content you own, under these Terms, our company and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
    <p className="mb-6">You are granted a limited license only for purposes of viewing the material contained on this Website and sharing your details for consultancy and/or registrations under any law enacted in India, on payment of consultancy fees.</p>

    <h2 className="text-xl font-semibold mb-4">Irrevocable Authorization By Client Upon Submission Of Application On Our Website</h2>
    <p className="mb-6">By availing the services of Our Company Name, Client hereby grants us the irrevocable right to mention an email address and mobile number belonging to our company in any application drafted and filed by our company with the FSSAI Department on your behalf. The purpose of mentioning our email address and mobile number on the application is to facilitate smooth communication, including but not limited to, checking the status of processing of the application, complying with applications reverted by the authorities, and delivering certificates once approved by the authorities. This authorization is provided solely for the purpose of providing hassle-free service to you, the client.</p>
    <p className="mb-6">Our Company agrees to treat all client information with the utmost confidentiality and will not disclose any sensitive information to any third party without prior consent from the client, except as required by law.</p>
    <p className="mb-6">Our Company shall have the right to access your client account with the FSSAI Department using the provided email address and mobile number, solely for the purposes outlined in the above section. This access will be used strictly for administrative and communication purposes and will not involve making any changes to your account without prior consent.</p>
    <p className="mb-6">Client agrees to indemnify and hold harmless Our Company from any and all claims, liabilities, damages, costs, and expenses, including legal fees, arising out of or in connection with any breach of these terms and conditions or any use of our services.</p>
    <p className="mb-6">Our Company reserves the right to amend these terms and conditions at any time without notice. Any changes will be effective immediately upon posting on the website. Your continued use of our services after any such changes constitutes acceptance of the new terms and conditions.</p>
    <p className="mb-6">These terms and conditions are governed by and construed in accordance with the laws of India and Client irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

    <h2 className="text-xl font-semibold mb-4">Restrictions</h2>
    <ul className="list-disc list-inside mb-6">
      <li>Publishing any Website material in any other media;</li>
      <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
      <li>Publicly performing and/or showing any Website material;</li>
      <li>Using this Website in any way that is or may be damaging to this Website;</li>
      <li>Using this Website in any way that impacts user access to this Website;</li>
      <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
      <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
      <li>Using this Website to engage in any advertising or marketing.</li>
    </ul>
    <p className="mb-6">Certain areas of this Website are restricted from being accessed by you and our company may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>

    <h2 className="text-xl font-semibold mb-4">Call Recording, Communication of Offers / Information Etc.</h2>
    <p className="mb-6">1) For training & quality purposes, we might record your call and the client gives his express content for call recording.</p>
    <p className="mb-6">2) Customer also gives his consent for contacting him via calls, SMS, WhatsApp, email or any other medium for providing offers / information of the product / service sold on this website or any other line of business of the owner of this website.</p>

    <h2 className="text-xl font-semibold mb-4">Your Content</h2>
    <p className="mb-6">In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display/share on this Website. By displaying Your Content, you grant our company a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
    <p className="mb-6">Your Content must be your own and must not be invading any third-party’s rights. Our company reserves the right to remove any of Your Content from this Website at any time without notice.</p>

    <h2 className="text-xl font-semibold mb-4">Use of Cookies</h2>
    <p className="mb-6">This webpage uses cookies to monitor the site and give you a better user experience:</p>
    <ol className="list-decimal list-inside mb-6">
      <li>Essential cookies, used for maintaining essential features on the site.</li>
      <li>Advertising cookies, used for displaying relevant ads.</li>
      <li>Third-party cookies, used for advertising and/or personalized content.</li>
    </ol>

    <h2 className="text-xl font-semibold mb-4">Privacy Policy & Communication & Digital Marketing</h2>
    <p className="mb-6">By submitting application or contact details on this website, user / service receiver / person making enquiry gives his irrevocable consent to the service provider to send communication of offers, discounts, changes, updating, deletions or any other subject matter by way of SMS, emails, WhatsApp, calls or any other means as service provider deems it.</p>

    <h2 className="text-xl font-semibold mb-4">No Warranties & Refunds</h2>
    <p className="mb-6">This Website is provided “as is,” with all faults, and our company expresses no representations, refunds or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>
    <p className="mb-6">Our company expressly provides that the consultancy fees paid by the client is non-refundable in any case and no claims in this regards will be entertained, except where our company is unable to provide the services for any reasons which are under the control of our company, its owners, officers, directors and employees.</p>

    <h2 className="text-xl font-semibold mb-4">Refunds</h2>
    <p className="mb-6">We follow a NO REFUND POLICY.</p>
    <p className="mb-6">It is hereby expressly provided that where client requests for cancellation / refund of fees after 24 hours of making payment or processing of his application whichever is earlier, no refund claims will be entertained. Decision on refunds taken by our company shall be final & binding upon the client.</p>
    <p className="mb-6">Note: As per our Company's Policy, refund determination and initiation of refund takes 12 - 15 working days. After refund is initiated, the bank takes 5 - 7 working days for credit of the amount to the client's bank account.</p>
    <p className="mb-6">Note: By submitting his application on this website, the client provides his free consent and all terms & conditions are accepted by the client.</p>

    <h2 className="text-xl font-semibold mb-4">Turn Around Time (TAT)</h2>
    <p className="mb-6">The time frame mentioned on the website is only indicative in nature, the actual time duration for delivery of services depends upon various external factors, which are out of reach of our company, its owners, officers, directors, and employees. Thus any such delay arising shall not be attributable to our company, its owners, officers, directors, and employees. And our company, its owners, officers, directors, and employees cannot be held responsible for delay in delivery of services.</p>
    <p className="mb-6">No refund claims shall be entertained on the basis of delay in delivery of services.</p>
    <ul className="list-disc list-inside mb-6">
      <li>Basic Registration (25 - 40 Working Days *)</li>
      <li>State Licence (30 - 60 Working Days *)</li>
      <li>Central Licence (45 - 60 Working Days *)</li>
    </ul>

    <h2 className="text-xl font-semibold mb-4">Limitation of Liability</h2>
    <p className="mb-6">In no event shall our company, its owners, officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. Our company, including its owners, officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>
    <p className="mb-6">Under any circumstances/events the liability of the owners, officers, directors, and employees will not exceed the amount of consultancy fees collected from the client.</p>

    <h2 className="text-xl font-semibold mb-4">Indemnification</h2>
    <p className="mb-6">You hereby indemnify to the fullest extent our company from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

    <h2 className="text-xl font-semibold mb-4">Severability</h2>
    <p className="mb-6">If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

    <h2 className="text-xl font-semibold mb-4">Variation of Terms</h2>
    <p className="mb-6">Our company is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>

    <h2 className="text-xl font-semibold mb-4">Assignment</h2>
    <p className="mb-6">Our company is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
  </div>
</div>

  );
}

export default TermsOfService;
