import React from 'react';

const PaymentFailed = () => {

  const handleRetryPayment = () => {
    window.href='https://foodlicenseonline.in/';
  };

  const handleGoHome = () => {
    window.location.href='/';
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-red-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-red-500">Payment Failed</h1>
        <p className="mt-4 text-lg text-gray-700">
          Unfortunately, your payment could not be processed. Please try again.
        </p>
        <div className="mt-6 flex space-x-4">
          {/* <button
            className="bg-red-500 text-white py-2 px-4 rounded-lg shadow hover:bg-red-600"
            onClick={handleRetryPayment}
          >
            Retry Payment
          </button> */}
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600"
            onClick={handleGoHome}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
