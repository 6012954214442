import React from 'react';

const GrievanceCell = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Grievance Resolution and Refund Policy</h1>
      <p className="mb-4">
        The Grievance Resolution and Refund Policy established by <a href="https://www.foodlicenseonline.in" className="text-blue-500 underline">foodlicenseonline.in</a> is dedicated to providing exceptional customer support and ensuring customers connect to the right experts, which are paramount for the sustainable growth of business. We firmly believe in offering the best services to our clients, but we also understand that sometimes things may not go as planned, leading to grievances or complaints.
      </p>
      <p className="mb-4">
        To address such situations promptly and efficiently, we have put in place a robust grievance redressal mechanism. This mechanism is designed to offer our customers a hassle-free way to register their complaints and seek resolutions as quickly as possible. Our primary goal is to minimize customer complaints and grievances through impeccable service delivery and an effective review process. If a redressal is not feasible, we are committed to providing refunds as appropriate.
      </p>
      <p className="mb-4">
        Our Grievance Resolution and Refund Policy align with Rule 5 (9) of the IT Rules, 2011, and are based on the following principles:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Fair Treatment:</strong> We believe in treating our customers fairly and with utmost respect throughout the complaint resolution process.</li>
        <li><strong>Informed Options:</strong> Customers are fully informed about the avenues available to escalate their complaints or grievances within our organization and their rights to seek alternative remedies.</li>
        <li><strong>Refund Policy:</strong> In cases where a resolution is not possible, we ensure that refunds of amounts paid by the customer are processed at the earliest.</li>
      </ul>
      <p className="mb-4">
        We have clearly defined the distinction between complaints and queries in our policy document:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Complaints:</strong> These are expressions of dissatisfaction related to our services or the complaints-handling process itself, where customers expect a response or resolution. Complaints can be communicated in person, over the telephone, or in writing.</li>
        <li><strong>Queries:</strong> Queries are questions or requests for information or guidance, often expressing doubts or seeking clarifications. Customers expect data or explanations in response to their queries.</li>
      </ul>
      <p className="mb-4">
        We want to assure our customers that we are committed to providing the best services at all times. If you need to raise a grievance, you can do so by contacting our Grievance Support team via email at <a href="mailto:unitedconsultancy051@gmail.com" className="text-blue-500 underline">unitedconsultancy051@gmail.com</a>. We aim to provide resolutions within a reasonable timeframe, and our support team will keep you informed about the status of your complaint throughout the process.
      </p>
      <p className="mb-4">
        Upon submitting a grievance, you will receive an acknowledgment or ticket within three working days, containing important details such as the date of receipt, a unique grievance number, the expected resolution date, and contact information for tracking purposes. We endeavor to resolve complaints within 30 days from the date of receipt.
      </p>
      <p className="mb-4">
        A complaint is considered closed when:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>We have fully addressed the complainant's request.</li>
        <li>The complainant has accepted our response in writing.</li>
        <li>The complainant has not responded to us within 8 weeks of our written response.</li>
        <li>The Grievance Redressal Officer certifies that we have fulfilled our contractual, statutory, and regulatory obligations, closing the complaint.</li>
      </ul>
      <p className="mb-4">
        To promote public awareness, we publish our grievance redressal procedure on our website and through other mediums.
      </p>
      <p className="mb-4">
        To ensure transparency, we maintain records of complaints for a minimum period of 1 year from the date of resolution.
      </p>
      <p className="mb-4">
        Periodic reviews of this policy are conducted to consider internal factors, such as changes in organizational structure or offered products and services, as well as external factors, including legislative changes or technological innovations. Additionally, results from internal or external audits may influence policy updates.
      </p>
    </div>
  );
};

export default GrievanceCell;
