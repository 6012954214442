import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import '../../src/index.css';
import fssaiLogo from '../assets/certificate-svgrepo-com.png'; // Import the image file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Main Header Section */}
      <nav className="bg-white p-1 border border-black">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="flex justify-center lg:justify-start items-center">
            {/* <a href="/">
              <img src={fssaiLogo} loading="lazy" className="img-fluid" width="130" alt="FOOD License Certificate" />
            </a> */}
          </div>
          <div className="flex flex-col items-center justify-center text-center font-bold">
            <h1 className="text-3xl font-bold mb-4">FSSAI FOOD LICENSE SERVICES</h1>
          </div>
          <div className="flex flex-col items-center justify-center lg:items-end text-black h-full">
            <div className="mb-2 text-center lg:text-right">
              <b>For Query:</b>
              <br />
              <a href="tel:+918847728338" className=" hover:text-green-600">
                <FontAwesomeIcon icon={faPhone} /> Call +91-8847728338
              </a>
              <br />
              <a href="https://wa.me/918847728338" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} size="lg" color="green" />
                <span className="ml-1"> Send WhatsApp Message</span>
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hamburger Menu for Mobile */}
      <nav className="bg-gray-200 border-t border-b border-black py-2 lg:hidden">
        <div className="flex justify-between items-center px-4">
          <div>
            <button onClick={toggleMenu} className="focus:outline-none">
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <ul className="flex flex-col items-center space-y-2 mt-2">
            <li className="py-2"><a href="/food-license-registration">Home</a></li>
            <li className="py-2"><a href="/about-us">About Us</a></li>
            <li className="py-2"><a href="/contact-us">Contact Us</a></li>
            <li className="py-2"><a href="/terms-of-service">Terms &amp; Conditions</a></li>
            <li className="py-2"><a href="/privacy-policy">Privacy Policy</a></li>
            <li className="py-2"><a href="/warranties-and-refunds">Warranties &amp; Refunds</a></li>
            <li className="py-2"><a href="/disclaimer-policy">Disclaimer Policy</a></li>
            <li className="py-2"><a href="/data-security-policy">Data Security Policy</a></li>
            <li className="py-2"><a href="/refund-policy">Refund Policy</a></li>
            <li className="py-2"><a href="/grievance">Grievance Redressal Policy</a></li>              
          </ul>
        )}
      </nav>

      {/* Desktop Menu */}
      <nav className="bg-gray-200 border-t border-b border-black py-2 hidden lg:block" style={{ backgroundColor: "rgb(0, 36, 107)",color: "white" }}  >
        <ul className="flex justify-center space-x-4">
          <li className="py-2"><a href="/food-license-registration">Home</a></li>
          <li className="py-2"><a href="/about-us">About Us</a></li>
          <li className="py-2"><a href="/contact-us">Contact Us</a></li>
          <li className="py-2"><a href="/terms-of-service">Terms &amp; Conditions</a></li>
          <li className="py-2"><a href="/privacy-policy">Privacy Policy</a></li>
          <li className="py-2"><a href="/warranties-and-refunds">Warranties &amp; Refunds</a></li>
          <li className="py-2"><a href="/disclaimer-policy">Disclaimer Policy</a></li>
          <li className="py-2"><a href="/data-security-policy">Data Security Policy</a></li>
          <li className="py-2"><a href="/refund-policy">Refund Policy</a></li>
          <li className="py-2"><a href="/grievance">Grievance Redressal Policy</a></li>              

        </ul>
      </nav>

      <section id="enquiry_form">
        <div className="container-fluid hidden-enq" id="process">
          <div className="row enq-bg">
            <div className="marquee-container overflow-hidden whitespace-nowrap bg-orange-500">
              <p className="marquee font-bold text-white ">
              FSSAI/Food License is mandatory for all food Businesses in India and This Platform is strictly designated for the exclusive utilization of external Experts for Registration License and is expressly prohibited for any alternative purposes. It is imperative to emphasize that this platform categorically refrains from offering any form of service, counsel, or direction pertaining to matters concerning FSSAI License. This website is entirely independent and operates with absolutely no affiliations to any government department.The food business operator may face imprisonment of upto 6 months and FSSAI license penalty up to rupees 5 lakhs for operating business without License. 
              </p>
            </div>
          </div>
          <div className="clb"></div>
        </div>
        <div className="container-fluid top-120" id="thank_you" style={{ display: 'none' }}>
          <div className="row enq-bg">
            <div className="col-md-12 enq-title text-center">
              <label className="label-text">Thank You, We will get back to you soon.</label>
            </div>
          </div>
          <div className="clb"></div>
        </div>
      </section>

      <Outlet />
    </>
  );
}

export default Header;
