import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-semibold text-center mb-4">Refund Policy</h1>
      <p className="text-lg mb-4">
        You can easily reach us with your grievance. Immediately our refund department will assess the matter, and if it finds non-catering of services on our part, we'll gladly refund your payment within 7 working days from the date of the refund request.
      </p>
      <p className="text-lg mb-4">
        We have a money-back guarantee policy if we are unable to process the application as committed by us.
      </p>
      <h2 className="text-xl font-semibold mb-2">Note:</h2>
      <ul className="list-disc pl-8 mb-4">
        <li className="text-lg mb-2">
          No refund shall be issued if you have already availed any complimentary service or discount offer which was attached to the paid service for which you are seeking a refund.
        </li>
        <li className="text-lg mb-2">
          We will deduct INR 300 (Three hundred rupees only) as administrative charges if the application would not be able to process due to non-submission of requisite documents as per government guidelines or any kind of non-cooperation from the applicant or any other circumstances which restrict us from processing the application.
        </li>
        <li className="text-lg mb-2">
          No refund shall be issued if we processed the registration/application as per the government guidelines and issuance of the certificate is pending or delayed on the part of a government department or officials.
        </li>
        <li className="text-lg mb-2">
          If any government fee, duty, challan, or any other sum paid in the course of processing the registration application by us. We will refund the payment received after reducing the government fee paid by us.
        </li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
