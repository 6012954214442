import React, { useState, useEffect } from 'react';

const DairyUnits = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(500);

  const [displayPrice, setDisplayPrice] = useState(900);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 900,
    '2': 1650,
    '3': 2500,
    '4': 3200,
    '5': 4100,
  };

  const capacityMapping = {
    "500": 'Upto 500 Ltrs of Milk per day Upto 2.5 MT of Milk Solids per annum',
    '501': '501 – 10,000 Ltrs of Milk per day OR 2.5 MT – 500 MT of Milk Solids per annum',
    '10001': '10001 – 50,000 Ltrs of Milk per day 501 MT – 2500 MT of Milk Solids per yr',
    '50000': 'More than 50,000 Liters of Milk per day More than 2500 MT of Milk Solid Per yr'
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 500) {
      setSelectedYears(1);
      setSelectedPrice(900);
      setDisplayPrice(900);
    }
    if (capacity == 501) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(7999);
      setDisplayPrice(7999);
    }
    if (capacity == 10001) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 50000) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection(capacityMapping[500]);
    setSelectedPrice(900);
    setSelectedYears(1);
  }, []);

  // useEffect(() => {
  //   const defaultEvent1 = { target: { value: displayYear } };
  //   handleYearsChange(defaultEvent1);

  // }, [displayYear,displayPrice]);

  return (
    <div className="turnoverDisplay">
      <div className="container bg-white p-2 text-justify mb-3">
        <h6 className="font-bold">Dairy Units including Milk Chilling Units:</h6>
        <span>
          Dairy processing means handling, processing, manufacturing, packing, storing, distribution & transportation of milk and milk products.
        </span>
      </div>
      <div>
        <label className="font-bold" id="exp">
          Select Production Capacity <span className="text-red-600">*</span>
        </label>
        <table className="table-style">
          <tbody>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="500"
                  checked={selectedCapacityKey == '500'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    Upto 500 Ltrs of Milk per day <br /> <b>OR</b> <br /> Upto 2.5 MT Milk Solids per / yr
                  </label>
                  <div id="turnoverx2" className="text-xs"></div>
                </div>
              </td>
              <td className="cell-style turnoverType text-center" rowSpan="4" >
                Select Radio Button On <br /> The Left Side To Display <br /> The Kind Of Registration / <br /> Licence Applicable In Your Case
              </td>
            </tr>

            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="501"
                  checked={selectedCapacityKey == '501'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    501 – 10,000 Ltrs of Milk / day <br /> <b>OR </b> <br /> 2.5 MT – 500 MT Milk Solids / yr
                  </label>
                  <div id="turnoverx2" className="text-xs"></div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="10001"
                  checked={selectedCapacityKey == '10001'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    10001 – 50,000 Ltrs of Milk / day <br /> <b>OR</b> <br /> 501 MT – 2500 MT Milk Solids / yr
                  </label>
                  <div id="turnoverz2" className="text-xs"></div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="50000"
                  checked={selectedCapacityKey == '50000'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    More than 50,000 Ltrs of Milk / day <br /> <b>OR</b> <br /> More than 2500 MT Milk Solid / yr
                  </label>
                  <div id="turnoverx3" className="text-xs"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {selectedCapacityKey == '500' &&
          <table className="table-style">
            <tbody>
              <tr>
                <td className="cell-style">
                  <label>Choose Certificate Validity / प्रमाणपत्र वैधता चुनें <span className="required">*</span></label>
                  <select className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" name="years[]" required
                    onChange={handleYearsChange} value={displayYear}
                  >
                    <option value="1">Certificate validity - 1 Year</option>
                    <option value="2">Certificate validity - 2 Years</option>
                    <option value="3">Certificate validity - 3 Years</option>
                    <option value="4">Certificate validity - 4 Years</option>
                    <option value="5">Certificate validity - 5 Years</option>
                  </select>
                </td>
                <td className="cell-style">
                  <span className="turnoverType hidden">Based on the selection of your inputs <h5 className="text-blue-900 font-bold text-base">FSSAI Basic Registration</h5> is applicable in your case</span>
                  <div className="fav">
                    <label></label>
                    <span className="text-black font-bold text-base">Applicable Price ₹{displayPrice}/-<br />Including Taxes & Fees</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="cell-style custom" colSpan="2" >Change required Certificate validity from the above dropdown.</td>
              </tr>
            </tbody>
          </table>
        }
        {selectedCapacityKey != '500' &&
          <div className="yearDisplay block">
            <table className="table-style">
              <tbody>
                <tr>
                  <td className="cell-style custom">
                    <span className="text-black font-bold text-lg pricing_displayx"></span>
                    <span className="customx">Default Certificate Validity : {displayYear} Year</span>
                  </td>
                  <td className="cell-style custom">
                    <span className="turnoverType hidden"></span>
                    <div className="fav">
                      <label></label>
                      <span className="text-black font-bold text-lg pricing_display">
                        Applicable Price ₹{displayPrice}/-
                        <br />
                        Including Taxes &amp; Fees
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        }
      </div>
      <br />
    </div>
  );
};

export default DairyUnits;
