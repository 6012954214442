import React, { useState, useEffect } from 'react';

const ECommerce = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(16999);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 16999   
  };

  const capacityMapping = {
    "1": ''  
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(900);
      setDisplayPrice(900);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(7999);
      setDisplayPrice(7999);
    }
    if (capacity == 3) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 4) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection(capacityMapping[1]);
    setSelectedPrice(16999);
    setSelectedYears(1);
  }, []);
  return (
    <div className="ECommerce">
      <div className="container bg-white p-3 text-justify mb-3">
        <h6 className="font-bold">E-commerce:</h6>
        <span>
          E-commerce also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions. Ecommerce is often used to refer to the sale of physical products online, but it can also describe any kind of commercial transaction that is
        </span>
      </div>

      <div style={{ display: 'none' }}>
        <label className="font-bold" id="exp">Select Turnover <span className=""> *</span></label>
        <div className="form-group">
          <input type="radio" name="turnover[]" onClick={() => {}} value="Turnover Above 20CR" checked />
          <label className="custom">Turnover Above 20CR</label>
          <div id="vegi1" style={{ fontSize: '12px' }}></div>
        </div>
      </div>

      <table className="table-style">
        <tbody>
          <tr>
            <td className="cell-style">
              <span className="text-black font-bold text-lg pricing_displayx"> </span>
              <span className="customx">Default Certificate Validity : 1 Year</span>
            </td>
            <td className="cell-style">
              <span className="turnoverType" style={{ display: 'none' }}></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">Applicable Price ₹16999/-<br />Including Taxes & Fees</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ECommerce;
