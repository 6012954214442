// config.js
const hostname = window.location.hostname;

let host = "";
let port=8000;
if (hostname === 'localhost' || hostname === '127.0.0.1') {
  host = 'http://localhost:'+port
} else {
  host = 'https://foodlicenseonline.in'
  // host = 'http://13.126.113.103:'+port  
}

console.log("host")
console.log(host)

const config = {
  backendServerUrl: host
};



export default config;
