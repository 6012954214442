import React, { useState, useEffect } from 'react';

const FoodManufacturer = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(900);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 900,
    '2': 1650,
    '3': 2500,
    '4': 3200,
    '5': 4100,
  };

  const capacityMapping = {
    "1": 'Production capacity upto 100 kgs / LTR / DAY OR Turnover upto 12 lacs',
    '2': 'Production capacity 101 KGS/LTR - 1MT / DAY',
    '3': 'Production capacity 1MT - 2MT / DAY OR Turnover upto 12 lacs pa',
    '4': 'Production capacity More than 2MT / DAY'
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(900);
      setDisplayPrice(900);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(7999);
      setDisplayPrice(7999);
    }
    if (capacity == 3) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 4) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection(capacityMapping[1]);
    setSelectedPrice(900);
    setSelectedYears(1);
  }, []);
  

  return (
    <div className="turnoverDisplay">
      <div className="container bg-white p-3 text-justify mb-2">
        <h6 className="font-bold">Manufacturer / Processor of any food other than Dairy Units, Vegetable Oil, Meat Processing, and Slaughtering Houses:</h6>
        <span>
          <b>Manufacturing / Processing Unit</b> means a person/entity which owns or operates an establishment that manufactures or processes a food product.
          <br /><br />
          This term includes, but is not limited to contract manufacturers, contract packers and other entities that manufactures or processes a food product.
          <br /><br />
          <b>Manufacturing or processing</b> means each step in conversion of raw material derived from livestock and agricultural produce into products for intermediate or final consumption. The process includes procurement, receipt, preparation, Manufacturing/processing, packaging, storage, distribution & transportation of food products.
          <br /><br />
          <b>Repacking</b> means packing of food product into different sizes with labelling after doing minimal processing as required like sorting, grading, sieving etc. from wholesale packages. The food product is not manipulated & the composition or formulation is not affected or changed.
          <br /><br />
          Repackers & Relabellers will also apply under manufacturing / processing units.
        </span>
      </div>
      <div>
        <label className="font-bold" id="exp">
          Select Production Capacity <span className="text-red-600">*</span>
        </label>
        <table className="table-style">
          <tbody>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="1"
                  checked={selectedCapacityKey == '1'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    Production capacity upto 100 kgs / LTR / DAY <br /><b>OR</b><br /> Turnover upto 12 lacs
                  </label>
                </div>
              </td>
              <td className="cell-style turnoverType text-center" rowSpan="4" >
                Select Radio Button On <br /> The Left Side To Display <br /> The Kind Of Registration / <br /> Licence Applicable In Your Case
              </td>
            </tr>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="2"
                  checked={selectedCapacityKey == '2'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    Production capacity 101 KGS/LTR - 1MT / DAY <br /><b>Note</b>: All grains, cereals, pulses milling units shall apply under this category without any limit on production capacity.
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="3"
                  checked={selectedCapacityKey == '3'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    Production capacity 1MT - 2MT / DAY <br /><b>OR</b><br /> Turnover upto 12 lacs pa
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover"
                  required
                  value="4"
                  checked={selectedCapacityKey == '4'}
                  onChange={handleCapacityChange}
                />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">
                    Production capacity More than 2MT / DAY <br /><b>NOTE</b>: NO GRAINS, CEREALS, PULSES MILLING UNITS SHALL APPLY UNDER THIS CATEGORY. THEY SHALL APPLY FOR STATE LICENCE ON ABOVE CATEGORY.
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {selectedCapacityKey == '1' &&
          <table className="table-style">
            <tbody>
              <tr>
                <td className="cell-style">
                  <label>Choose Certificate Validity / प्रमाणपत्र वैधता चुनें <span className="required">*</span></label>
                  <select className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" name="years[]" required
                    onChange={handleYearsChange} value={displayYear}
                  >
                    <option value="1">Certificate validity - 1 Year</option>
                    <option value="2">Certificate validity - 2 Years</option>
                    <option value="3">Certificate validity - 3 Years</option>
                    <option value="4">Certificate validity - 4 Years</option>
                    <option value="5">Certificate validity - 5 Years</option>
                  </select>
                </td>
                <td className="cell-style">
                  <span className="turnoverType hidden">Based on the selection of your inputs <h5 className="text-blue-900 font-bold text-base">FSSAI Basic Registration</h5> is applicable in your case</span>
                  <div className="fav">
                    <label></label>
                    <span className="text-black font-bold text-base">Applicable Price ₹{displayPrice}/-<br />Including Taxes & Fees</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="cell-style custom" colSpan="2" >Change required Certificate validity from the above dropdown.</td>
              </tr>
            </tbody>
          </table>
        }
        {selectedCapacityKey != '1' &&
          <div className="yearDisplay block">
            <table className="table-style">
              <tbody>
                <tr>
                  <td className="cell-style custom">
                    <span className="text-black font-bold text-lg pricing_displayx"></span>
                    <span className="customx">Default Certificate Validity : {displayYear} Year</span>
                  </td>
                  <td className="cell-style custom">
                    <span className="turnoverType hidden"></span>
                    <div className="fav">
                      <label></label>
                      <span className="text-black font-bold text-lg pricing_display">
                        Applicable Price ₹{displayPrice}/-
                        <br />
                        Including Taxes &amp; Fees
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        }
        <div className="form-group fav p-2"></div>
      </div>
    </div>
  );
};

export default FoodManufacturer;
