import React, { useState,useEffect } from 'react';
import config from './config';
const backendServerUrl = config.backendServerUrl;
import FoodLicenseReport from './FoodLicenseReport'

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // setUserId(localStorage.getItem("user_id"));
        // localStorage.getItem('isLoggedIn');
        console.log(localStorage.getItem('isLoggedIn'))
        if(localStorage.getItem('isLoggedIn') == 'true'){
            setIsLoggedIn(true)
        }else{
            setIsLoggedIn(false)
        }
        // searchReport();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendServerUrl}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            // response = (response.json());

            // if (!response.success) {
            //     throw new Error('Network response was not ok');
            // }

            // Handle response data if needed
            const data = await response.json();
            if (data.success) {
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', true);

            }
            // alert("Registration successful!!")
            // console.log('Success:', data);

        } catch (error) {
            alert("Login Failed !!")
            setIsLoggedIn(false);
        }
        // Perform authentication logic (e.g., call API)
        // For simplicity, we'll just call the onLogin prop here

    };

    return (
        <div >

            {isLoggedIn ? (
                <FoodLicenseReport />
            ) : (
                <div className="max-w-md mx-auto mb-10">
                    <h2 className="text-2xl font-semibold mb-4">Login</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="mt-1 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Sign in
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default LoginPage;
