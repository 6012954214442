import React, { useState, useEffect } from 'react';
import config from './config';



const backendServerUrl = config.backendServerUrl;
function ViewFoodRegistration() {
    const [formData, setFormData] = useState({
        name_of_applicant: '',
        name_of_company: '',
        email_id: '',
        mobile_no: '',
        food_category: '',
        type_of_business: '',
        house_no: '',
        area: '',
        city: '',
        district: '',
        state: '',
        pin: '',
        selection: '',
        years: 1,
        price: 0
    });
    const [errors, setErrors] = useState({});

    const [selectedSelection, setSelection] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(0);
    // const [formData.type_of_business, setformData.type_of_business] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');

    useEffect(() => {
        // setUserId(localStorage.getItem("user_id"));
        // localStorage.getItem('isLoggedIn');
        if (localStorage.getItem('isLoggedIn') != 'true') {
            window.location.href = 'login'
        }
        // searchReport();
    }, []);

    useEffect(() => {

        showForm(getParameter('id'));
        // searchReport();
    }, []);

    useEffect(() => {
        console.log(JSON.stringify(formData, null, 2));
    });


    const showForm = (id) => {
        let postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                form_id: id,
            })
        };

        fetch(`${backendServerUrl}/api/show_form`, postData)
            .then(
                res => (res.json())
            )
            .then(
                (res) => {

                    console.log(res.data[0]);
                    const newData = res.data[0];
                    setFormData(newData);
                    console.log(formData);
                }
            );
    };

    const getParameter = (param) => {
        var urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    return (
        <div>
            <section className="relative bg-white" id="unique-section-id">
                <div className="container mx-auto">

                    <div className="row">
                        <div className="col-md-6 top-15 pt-5" id="applyForm">
                            <div className="col-md-12 regi-panel2 div-shadow-box bord-rad top-20">
                                <div className="col-md-12 registration-title border-none font-semibold mb-1 text-center">Registration Form</div>
                            </div>
                            {/* <div className="clb"></div> */}
                            <div className="col-md-12 form-bg2 div-shadow-box">
                                <div className=''>
                                    <form>
                                        <div className="row flex flex-col space-y-4">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Name of Applicant / आवेदक का नाम  <span className="text-red-500">*</span></label>
                                                    <input name="name_of_applicant" value={formData.name_of_applicant}
                                                        type="text"
                                                        id="txtName"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Name of Applicant / Company"
                                                        required
                                                    />

                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Email Id /ईमेल आईडी <span className="text-red-500">*</span></label>
                                                    <input value={formData.email_id}
                                                        name="email_id"
                                                        type="text"
                                                        maxLength="100"
                                                        id="txtEmail"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Email Id"
                                                        required
                                                    />
                                                    {errors.email_id && <span className="text-red-500">{errors.email_id}</span>}


                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Mobile No/मोबाइल नंबर  <span className="text-red-500">*</span></label>
                                                    <input value={formData.mobile_no}
                                                        name="mobile_no"
                                                        type="text"
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="98XXXXXXXX (Without 0 or +91)"
                                                        title="Enter valid 10 digits mobile number"
                                                        required
                                                    />
                                                    {errors.mobile_no && <span className="text-red-500">{errors.mobile_no}</span>}

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Name Of Business / Firm / Company / व्यवसाय / फर्म / कंपनी का नाम *<font color="red">*</font></label>
                                                    <input
                                                        name="name_of_applicant" type="text"
                                                        value={formData.name_of_company}
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Food Category / खाद्य श्रेणी का नाम *</label>
                                                    <select value={formData.food_category}
                                                        name="food_category"
                                                        id="ddlNatureBusiness"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Dairy products and analogues, excluding products of food category 2.0">Dairy products and analogues, excluding products of food category 2.0</option>
                                                        <option value="Fats and oils, and fat emulsions">Fats and oils, and fat emulsions</option>
                                                        <option value="Edible ices, including sherbet and sorbet">Edible ices, including sherbet and sorbet</option>
                                                        <option value="Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera)">Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera)</option>
                                                        <option value="Confectionery">Confectionery</option>
                                                        <option value="Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes">Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes</option>
                                                        <option value="Bakery products">Bakery products</option>
                                                        <option value="Fish and fish products, including molluscs, crustaceans, and echinoderms">Fish and fish products, including molluscs, crustaceans, and echinoderms</option>
                                                        <option value="Eggs and egg products">Eggs and egg products</option>
                                                        <option value="Sweeteners, including honey">Sweeteners, including honey</option>
                                                        <option value="Salts, spices, soups, sauces, salads and protein products">Salts, spices, soups, sauces, salads and protein products</option>
                                                        <option value="Foodstuffs intended for particular nutritional uses">Foodstuffs intended for particular nutritional uses</option>
                                                        <option value="Beverages, excluding dairy products">Beverages, excluding dairy products</option>
                                                        <option value="Ready-to-eat savouries">Ready-to-eat savouries</option>
                                                        <option value="Prepared Foods">Prepared Foods</option>
                                                        <option value="Substances added to food">Substances added to food</option>
                                                        <option value="others">Others</option>   </select>
                                                    {errors.food_category && <span className="text-red-500">{errors.food_category}</span>}

                                                </div>
                                            </div>


                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Kind Of Business / व्यवसाय का प्रकार * </label>
                                                    <select value={formData.type_of_business}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            changeFoodCategory(event);
                                                        }}

                                                        name="type_of_business"
                                                        id="ddlFoodCategory"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="SmallPettyFoodBusinessOperators">1. Small / Petty Food Business Operators</option>
                                                        <option value="DairyUnits">2. Dairy Units including Milk Chilling Units</option>
                                                        <option value="VegetableOilProcessingUnits">3. Vegetable Oil Processing Units</option>
                                                        <option value="SlaughteringHouse">4. Slaughtering House</option>
                                                        <option value="FishPoultrySeller">5. Fish Seller /  Poultry Products Seller</option>
                                                        <option value="MeatProcessing">6. Meat Processing</option>
                                                        <option value="FoodManufacturer">7. Manufacturer / Processor of any food other than Dairy Units, Vegetable Oil....</option>
                                                        <option value="ProprietaryFood">8. Proprietary Food</option>
                                                        <option value="FoodSupplements">9. Food or Health Supplements and Nutraceuticals etc.</option>
                                                        <option value="RadiationProcessing">10. Radiation processing of food</option>
                                                        <option value="SubstancesAddedToFood">11. Substances added to Food</option>
                                                        <option value="ColdStorage">12. Warehouse - Cold/Refrigerated</option>
                                                        <option value="AtmosphericControlledStorage">13. Warehouse – Atmospheric Controlled + Cold</option>
                                                        <option value="StorageWithoutAtmosphericControlled">14. Warehouse Without Atmospheric Controlled + Cold</option>
                                                        <option value="Transporter">15. Transporter</option>
                                                        <option value="Wholesaler">16. Wholesaler</option>
                                                        <option value="Distributor">17. Distributor</option>
                                                        <option value="Retailer">18. Retailer</option>
                                                        <option value="Hotel">19. Hotel</option>
                                                        <option value="Restaurants">20. Restaurants</option>
                                                        <option value="Club">21. Club/Canteen</option>
                                                        <option value="Caterer">22. Caterer</option>
                                                        <option value="FoodVendingAgencies">23. Food Vending Agencies</option>
                                                        <option value="Dhaba">24. Dhaba</option>
                                                        <option value="Importers">25. Importers</option>
                                                        <option value="Exporter">26. Exporters</option>
                                                        <option value="ECommerce">27. E-commerce</option>
                                                        <option value="HeadOffice">28. Head Office/Registered Office</option>
                                                        <option value="Relabellers">29. Relabellers And Repackers</option>
                                                        <option value="Other">30. Any Other Food Business not mentioned above</option>
                                                    </select>


                                                    {errors.type_of_business && <span className="text-red-500">{errors.type_of_business}</span>}

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Capacity/Turnover	<font color="red">*</font></label>
                                                    <input
                                                        name="name_of_applicant" type="text"
                                                        value={formData.selection}
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Year	<font color="red">*</font></label>
                                                    <input
                                                        name="name_of_applicant" type="text"
                                                        value={formData.years}
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Price	<font color="red">*</font></label>
                                                    <input
                                                        name="name_of_applicant" type="text"
                                                        value={formData.price}
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                            </div>





                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Address</label>
                                                </div>
                                            </div>
                                            <div className='grid gap-4 grid-cols-2'>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.house_no}
                                                            name="house_no"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtHouse"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="HOUSE NO/SHOP NO/PLOT NO"
                                                        />
                                                        {errors.house_no && <span className="text-red-500">{errors.house_no}</span>}

                                                    </div>
                                                </div>
                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.area}
                                                            name="area"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtAreaLocality"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Area / Locality"
                                                        />
                                                        {errors.area && <span className="text-red-500">{errors.area}</span>}

                                                    </div>
                                                </div>
                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.city}
                                                            name="city"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtCity"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="City"
                                                        />
                                                        {errors.city && <span className="text-red-500">{errors.city}</span>}

                                                    </div>
                                                </div>

                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        {/* <label className="font-semibold mb-1">Pin</label> */}
                                                        <input value={formData.pin}
                                                            name="pin"
                                                            type="text"
                                                            maxLength="6"
                                                            id="txtPin"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="PIN"
                                                        />
                                                        {errors.pin && <span className="text-red-500">{errors.pin}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid gap-4 grid-cols-2'>
                                                <div className="col-md-12">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="font-semibold mb-1">State	<font color="red">*</font></label>
                                                            <input
                                                                name="name_of_applicant" type="text"
                                                                value={formData.state}
                                                                id="txtPhone1"
                                                                className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="font-semibold mb-1">District	<font color="red">*</font></label>
                                                            <input
                                                                name="name_of_applicant" type="text"
                                                                value={formData.district}
                                                                id="txtPhone1"
                                                                className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                              
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <br></br>
                    

                    </div>
                </div>
            </section>
        </div>
    )
}
export default ViewFoodRegistration;