import React, { useState, useEffect, useContext } from 'react';
import config from './config';
import moment from 'moment';

const backendServerUrl = config.backendServerUrl;
const FoodLicenseReport = () => {

    const [search_foodLicense_report, setSearchReport] = useState('');

    const [applications, setApplications] = useState([]);
    const [application_id, setapplicationId] = useState(0);
    const [id, setId] = useState(0);
    const [transiction_id, settransictionId] = useState(0);
    const [application_type, setApplicationType] = useState('');
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [payment_status, setPaymentStatus] = useState('pending');




    useEffect(() => {
        // setUserId(localStorage.getItem("user_id"));
        if (localStorage.getItem('isLoggedIn') != 'true') {
            window.location.href = 'login'
        } else {
            showFoodLicenseReport();
        }
        // searchReport();
    }, []);





    const showFoodLicenseReport = () => {
        let postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                // user_id: localStorage.getItem("user_id"),
                // user_id:context.user_id
            })
        };

        fetch(`${backendServerUrl}/api/show_food_license_report`, postData)
            .then(
                res => (res.json())
            )
            .then(
                (res) => {
                    console.log(res);
                    setStartDate(res.data);
                    setEndDate(res.data);
                    setApplications(res.data);
                    // setTotalApplications(res.totalItems);                  

                }
            );
    };

    // search report
    const searchFoodLicenseReport = (event) => {
        let postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                start_date: start_date,
                end_date: end_date,
                search: search_foodLicense_report
            })
        };

        fetch(`${backendServerUrl}/api/search_foodLicense_report`, postData)
            .then(res => res.json())
            .then(res => {
                console.log(res);

                setApplications(res.data);
            });
    };

    const handlePaymentStatusChange = (e) => {
        const status = e.target.value;
        setPaymentStatus(status);
        onChange(status);
    };
    // const ChangeapplicationStatus = (event) => {
    //     let postData = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             application_status: event.target.value,
    //             application_id: event.target.getAttribute('application_id'),
    //         }),
    //     };

    //     fetch('http://localhost:8081/application_status', postData)
    //         .then((res) => res.json())
    //         .then((res) => {
    //             console.log(res);
    //             alert('application status changed successfully');
    //         });
    // };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-yellow-200 text-yellow-800';
            case 'success':
                return 'bg-green-400 text-green-800';
            case 'failed':
                return 'bg-red-400 text-red-800';
            default:
                return '';
        }
    };

    return (
        <div className='ml-2 mr-2'>
            <div className="flex flex-col lg:flex-row">
                <div className="lg:w-1/3" />
                <div className="lg:w-1/3">
                    <div className="mb-3 mt-3">
                        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                            Enter Start Date:
                        </label>
                        <input
                            id="startDate"
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            type="date"
                            value={start_date}
                            onChange={(e) => setStartDate(e.target.value)}
                            name="startDate"
                        />
                    </div>
                    <div className="mb-3 mt-3">
                        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                            Enter End Date:
                        </label>
                        <input
                            id="endDate"
                            type="date"
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            value={end_date}
                            onChange={(e) => setEndDate(e.target.value)}
                            name="endDate"
                        />
                    </div>
                    {/* search report */}
                    <div className="mb-3 mt-3">
                        <div className="flex">
                            <input
                                value={search_foodLicense_report}
                                onChange={(e) => setSearchReport(e.target.value)}
                                type="search"
                                name="search"
                                id="form1"
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Search By Name of Applicant / Company"
                            />

                        </div>
                    </div>
                    <select
                        value={payment_status}
                        onChange={(e) => setPaymentStatus(e.target.value)}
                        className="block w-full mt-1 p-2 border border-gray-300 rounded-none focus:outline-none"
                    >
                        <option value="">Select Payment Status</option>
                        {/* <option value="pending">Pending</option> */}
                        <option value="paid">Paid</option>
                        <option value="failed">Failed</option>
                    </select>
                    <button
                        className="mt-3 mb-5 inline-flex items-center px-4 py-2 border border-transparent rounded-none shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={searchFoodLicenseReport}
                    >
                        Show Report
                    </button>
                </div>
            </div>



            <div className="overflow-auto lg:col-span-2">
                <table className="min-w-full border border-collapse">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="border px-3 py-2">No.</th>
                            <th className="border px-3 py-2">Action</th>
                            <th className="border px-4 py-2">Payment Status</th>
                            <th className="border px-12 py-2">Date</th>
                            {/* <th className="border px-4 py-2">Application Type</th> */}
                            <th className="border px-4 py-2">Name of Applicant</th>
                            <th className="border px-4 py-2"> Email Id </th>
                            <th className="border px-4 py-2">Mobile No </th>
                            <th className="border px-4 py-2"> Name Of Business</th>
                            <th className="border px-4 py-2">Food Category</th>
                            <th className="border px-4 py-2">Kind Of Business </th>
                            <th className="border px-4 py-2">Capacity/Turnover </th>
                            <th className="border px-4 py-2">No.Of Years </th>
                            <th className="border px-4 py-2">Price</th>
                            <th className="border px-4 py-2">House no</th>
                            <th className="border px-4 py-2">area</th>
                            <th className="border px-4 py-2">city</th>
                            <th className="border px-4 py-2">District</th>
                            <th className="border px-4 py-2">State</th>
                            <th className="border px-4 py-2">pin</th>

                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {applications.map((application, index) => (
                            <tr key={index}>
                                <td className="border px-3 py-2">{index + 1}</td>
                                <td className="border px-4 py-2" >
                                    <button
                                        onClick={() => (window.open(window.location.origin + "/view-food-registration?id=" + encodeURIComponent(application.id), "_blank"))}
                                        type="button"
                                        className="px-4 py-2 bg-blue-500 text-white rounded-r hover:bg-blue-600 focus:outline-none">
                                        Open
                                    </button>
                                </td>


                                <td className={`border px-4 py-2`}>
                                    <button
                                        type="button"
                                        className={`px-4 py-2 text-white rounded-r focus:outline-none2 ${getStatusColor(application.payment_status)} rounded capitalize`} >
                                        {application.payment_status}
                                    </button>
                                </td>
                                <td className="border px-4 py-2">{moment(application.created_at).format("DD-MMM-YYYY h:mm A")}</td>
                                {/* <td className="border px-4 py-2">{application.application_type}</td> */}
                                <td className="border px-4 py-2">{application.name_of_company}</td>
                                <td className="border px-4 py-2">{application.email_id}</td>
                                <td className="border px-4 py-2">{application.mobile_no}</td>
                                <td className="border px-4 py-2">{application.name_of_applicant}</td>
                                <td className="border px-4 py-2">{application.food_category}</td>
                                <td className="border px-4 py-2">{application.type_of_business}</td>
                                <td className="border px-4 py-2">{application.selection}</td>
                                <td className="border px-4 py-2">{application.years}</td>
                                <td className="border px-4 py-2">{application.price}</td>
                                <td className="border px-4 py-2">{application.house_no}</td>
                                <td className="border px-4 py-2">{application.area}</td>
                                <td className="border px-4 py-2">{application.city}</td>
                                <td className="border px-4 py-2">{application.district}</td>
                                <td className="border px-4 py-2">{application.state}</td>
                                <td className="border px-4 py-2">{application.pin}</td>
                                {/* <td className="border px-4 py-2">{application.no_of_years}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



        </div>
    );
};

export default FoodLicenseReport;
