import React from 'react';

const DataSecurityPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Data Security Policy</h1>
            <p className="mb-4">This Policy was last updated on: 22nd May, 2019</p>
            <h2 className="text-2xl font-bold mb-2">1. Introduction</h2>
            <p className="mb-4">
                The United Consultancy Services Private Limited (“United”, “We”, “Us” or “Our”) data security policy covers our Services and Websites located at www.foodlicenseonline.in (hereinafter collectively referred to as “Service(s)”).
            </p>
            <p className="mb-4">
                We value the unwavering trust that our users place in us as custodians of their data. We understand our responsibility and take appropriate care to protect and secure your information seriously as described in our security practices below.
            </p>
            <p className="mb-4">
                The terms “customer” “you” “your” “user” and “users” refer to all individuals and other persons who access or use our services, including, without limitation, any companies, organizations, or other legal entities that register accounts or otherwise access or use the Services through their respective employees, agents, or representatives.
            </p>
            <h2 className="text-2xl font-bold mb-2">2. Compliance</h2>
            <p className="mb-4">
                United is compliant with the Payment Data Security Standards via the third-party payment providers we use and can therefore accept or process credit card information securely in accordance with these standards.
            </p>
            <h2 className="text-2xl font-bold mb-2">3. Access Control</h2>
            <p className="mb-4">
                Access to our technology resources is only permitted through secure connectivity (for e.g. Https) and requires authentication. Our password policy requires complexity, expiration, lockout and disallows reuse. We grant access on a need-to-know basis, review permissions quarterly, and revoke access immediately after employee termination.
            </p>
            <h2 className="text-2xl font-bold mb-2">4. Security Policies</h2>
            <p className="mb-4">
                We review and update our security policies at least annually. Our employees are obligated to acknowledge policies on an annual basis and are provided training for assuring data security and job-specific security and skill development for key job functions.
            </p>
            <h2 className="text-2xl font-bold mb-2">5. Physical Security</h2>
            <p className="mb-4">
                Our information systems and technical infrastructure are hosted within world-class Data Center located in India. Physical security controls at our data centers include camera surveillance, visitor logs, security personnel.
            </p>

            <h2 className="text-2xl font-bold mb-2">6. Personnel Screening</h2>
            <p className="mb-4">
                We conduct background research at the time of hire (to the extent permitted or facilitated by applicable laws and countries). In addition, we communicate our data security policies to all personnel (who must acknowledge this) and require new employees to sign non-disclosure agreements and provide ongoing privacy and security training.
            </p>
            <h2 className="text-2xl font-bold mb-2">7. Penetration Testing and System Vulnerability Assessments</h2>
            <p className="mb-4">
                We have a vulnerability assessment program which includes periodic scans, identification, and remediation of security vulnerabilities on servers, network equipment, and applications. All networks, including test and production environments, are regularly scanned using trusted third-party vendors.
            </p>
            <p className="mb-4">
                We also conduct regular internal and external penetration tests and remediate according to severity for any results found.
            </p>
            <h2 className="text-2xl font-bold mb-2">8. Data Transit Encryption</h2>
            <p className="mb-4">
                We encrypt data in transit using Industry Standard SSL.
            </p>
            <h2 className="text-2xl font-bold mb-2">9. Asset Management</h2>
            <p className="mb-4">
                We maintain electronic records for identification, classification, retention, and disposal of assets. Company-issued devices are equipped with hard disk encryption and up-to-date antivirus software. Only company-issued devices are permitted to access corporate and production networks.
            </p>

            <h2 className="text-2xl font-bold mb-2">10. Product Development</h2>
            <p className="mb-4">
                Our development team employs secure coding techniques and best practices. Our Developers are formally trained in secure web application development practices upon hire and at least once every six months.
            </p>
            <h2 className="text-2xl font-bold mb-2">11. Information Security Incident Response Management</h2>
            <p className="mb-4">
                We maintain security incident response policies and procedures covering the initial response, investigation, public communication, and remediation. These policies are reviewed regularly and tested bi-annually.
            </p>
            <h2 className="text-2xl font-bold mb-2">12. Notification of Breach</h2>
            <p className="mb-4">
                Despite all the best efforts, no method of transmission over the Internet, or method of electronic storage, is perfectly secure. Therefore, we cannot guarantee absolute security. However, if we learn of a security breach, we will notify affected users so that they can take appropriate protective steps. We are committed to keeping our customers fully informed of any matters relevant to the security of their account and to providing customers all information necessary for them to meet their own regulatory reporting obligations.
            </p>
            <h2 className="text-2xl font-bold mb-2">13. Business Continuity</h2>
            <p className="mb-4">
                Our databases are backed up on a regular basis and are verified regularly. Backups are encrypted and stored within the production environment to preserve their confidentiality and integrity and are tested regularly to ensure availability.
            </p>
            <h2 className="text-2xl font-bold mb-2">14. Customer Responsibilities</h2>
            <p className="mb-4">
                Keeping your data secure also requires that the user maintains the security of their account by using sufficiently complicated passwords and storing them safely. Users should also ensure that they have sufficient security on their own systems.
            </p>
            <h2 className="text-2xl font-bold mb-2">15. Logging and Monitoring</h2>
            <p className="mb-4">
                Our systems log information to a centrally managed log repository for troubleshooting, security reviews, and analysis by authorized personnel. We will provide users with reasonable assistance in the event of a security incident impacting their account.
            </p>

            <p className="mb-4">
                In case of any queries that you may have please reach our Information Security Officer at unitedconsultancy051@gmail.com.
            </p>
        </div>
    );
};

export default DataSecurityPolicy;
