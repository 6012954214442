import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Fssai Registration Privacy Policy</h1>
      <p className="mb-4">
        At <a href="https://foodlicenseonline.in/" className="text-blue-500 underline">foodlicenseonline.in</a>, one of our main priorities is the privacy of our visitors.
      </p>
      <p className="mb-4">
        Should you have any further inquiries or need additional details regarding our Privacy Policy, please feel free to reach out to us at <a href="mailto:unitedconsultancy051@gmail.com" className="text-blue-500 underline">unitedconsultancy051@gmail.com</a>. We are here to assist you and provide any necessary information you may require. Don't hesitate to contact us; our team is ready to address your concerns. Simply drop us an email at the aforementioned address, and we will respond to your query as soon as possible.
      </p>
      <p className="mb-4">
        Your privacy is important to us, and we are committed to ensuring the security and confidentiality of your personal information. Should you have any questions or require clarification regarding our privacy practices, our dedicated team is available to provide you with the assistance you need. Reach out to us at <a href="mailto:unitedconsultancy051@gmail.com" className="text-blue-500 underline">unitedconsultancy051@gmail.com</a>, and we will be more than happy to help.
      </p>
      <h2 className="text-xl font-bold mb-2">Information we collect</h2>
      <p className="mb-4">
        We want to ensure complete transparency about the personal information we ask from you and the reasons behind it. Whenever we ask you to provide your personal information, we will clearly state the purposes for which we need it.
      </p>
      <p className="mb-4">
        If you contact us directly, we may collect additional information about you, such as your name, email address, phone number, the content of your message, any attachments you send, and any other details you choose to share.
      </p>
      <p className="mb-4">
        During the account creation process, we may request your contact information, including your name, business name, address, email address, and telephone number.
      </p>
      <p className="mb-4">
        Please be assured that we make the privacy and security of your personal information a priority. The reasons for collecting specific details will always be explained, promoting transparency and ensuring you have a clear understanding of how and why your information is being used.
      </p>
    </div>

  );
};

export default PrivacyPolicy;
