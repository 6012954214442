import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <div className="mb-8">
        <p className="mb-2">Fssai registration consultancy services</p>
      </div>
      <div className="mb-8">
        <p className="mb-2">Office Hours: Monday to Saturday, 10am to 6pm.</p>
        <p className="mb-2">Address:  Dinkar Society, Mangalwar Peth ,Madhavnagar, Sangli. Pin:416406</p>
        <p className="mb-2">Phone No. <a href="tel:+918847728338">+91 8847728338</a></p>
        <p className="mb-2">Email: <a href="mailto:unitedconsultancy051@gmail.com">unitedconsultancy051@gmail.com</a></p>
        <p className="mb-2">Website: <a href="http://www.foodlicenseonline.in">www.foodlicenseonline.in</a></p>
      </div>
    </div>
  );
};

export default ContactUs;
