import React, { createContext, useContext, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Nav from './inc/Nav';
import Navi from './inc/Nav';
import Layout from './inc/Header';
import Header from './inc/Header';
import Footer from './inc/Footer';
import AboutUs from './inc/AboutUs';
import TermsOfService from './inc/TermsOfService';
import PrivacyPolicy from './inc/PrivacyPolicy';
import WarrantiesAndRefunds from './inc/WarrantiesAndRefunds';
import DisclaimerPolicy from './inc/DisclaimerPolicy';
import DataSecurityPolicy from './inc/DataSecurityPolicy';
import ContactUs from './inc/ContactUs';
import LoginPage from './Pages/LoginPage';
import Payment from './Pages/Payment';
import RefundPolicy from './inc/RefundPolicy';
import ViewFoodRegistration from './Pages/ViewFoodRegistration';
import PaymentSuccess from './Pages/PaymentSuccess';
import ThankYou from './Pages/ThankYou';
// import Report from './Pages/Report';
import FoodRegistration from './Pages/FoodRegistration';
import FoodLicenseReport from './Pages/FoodLicenseReport';
import PaymentFailed from './Pages/PaymentFailed';
import NotFound from './NotFound';
import GrievanceCell from './inc/GrievanceCell';
import PricingPage from './inc/PricingPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route path="/food-license-registration" element={<FoodRegistration />} />
          <Route path="/food-license-report" element={<FoodLicenseReport />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/warranties-and-refunds" element={<WarrantiesAndRefunds />} />
          <Route path="/disclaimer-policy" element={<DisclaimerPolicy />} />
          <Route path="/data-security-policy" element={<DataSecurityPolicy />} />
          <Route path="/view-food-registration" element={<ViewFoodRegistration />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failed" element={<PaymentFailed />} />
          <Route path="/grievance" element={<GrievanceCell />} />          
          <Route path="/thank-you" element={<ThankYou />} />      
          <Route path="/pricing" element={<PricingPage />} />      
              
          <Route path="*" element={<NotFound />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route index element={<FoodRegistration />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

// Create a root container
const container = document.getElementById('root');
const root = createRoot(container); // createRoot API from React 18
root.render(<App />);

reportWebVitals();
