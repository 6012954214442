import React, { useState, useEffect } from 'react';

const FoodVendingAgencies = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(900);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 900,
    '2': 1650,
    '3': 2500,
    '4': 3200,
    '5': 4100,
  };

  const capacityMapping = {
    "1": 'Turnover upto Rs. 12 Lacs per annum',
    '2': 'Upto 100 Vending Machines in only one state / ut',
    '3': 'More than 100 Vending Machines and / or located in two or more states / UTS',
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(900);
      setDisplayPrice(900);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(6999);
      setDisplayPrice(6999);
    }
    if (capacity == 3) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }
    if (capacity == 4) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection(capacityMapping[1]);
    setSelectedPrice(900);
    setSelectedYears(1);
  }, []);
  return (
    <div className="FoodVendingAgencies">
      <div className="container bg-white p-3 text-justify mb-3">
        <h6 className="font-bold">Food Vending Agencies:</h6>
        <span>
          Sale of Packaged / Fresh food from a temporary or fixed stall/cart/machine by an individual or by automation.
        </span>
      </div>

      <div>
        <label className="font-bold" id="exp">Select The Turnover / टर्नओवर का चयन करें <span className=""> *</span></label>
        <table className="table-style">
          <tbody>
            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover[]"


                  required={true}
                  value="1"
                  checked={selectedCapacityKey == '1'}

                  onChange={handleCapacityChange} />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom"> Turnover upto Rs. 12 Lacs per annum</label>
                </div>
              </td>
              <td className="cell-style turnoverType text-center" rowSpan="3">
                Select Radio Button On <br /> The Left Side To Display <br /> The Kind Of Registration / <br /> Licence Applicable In Your Case
              </td>
            </tr>

            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover[]"


                  required={true}
                  value="2"
                  checked={selectedCapacityKey == '2'}

                  onChange={handleCapacityChange} />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom"> Upto 100 Vending Machines in only one state / ut</label>
                  <div id="turnoverx2" className="text-sm"></div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="cell-style">
                <input
                  type="radio"
                  name="turnover[]"


                  required={true}
                  value="3"
                  checked={selectedCapacityKey == '3'}

                  onChange={handleCapacityChange} />
              </td>
              <td className="cell-style w-auto">
                <div>
                  <label className="custom">More than 100 Vending Machines and / or located <br /> in two or more states / UTS</label>
                  <div id="turnoverz2" className="text-sm"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {selectedCapacityKey == '1' &&
          <table className="table-style">
            <tbody>
              <tr>
                <td className="cell-style">
                  <label>Choose Certificate Validity / प्रमाणपत्र वैधता चुनें <span className="required">*</span></label>
                  <select className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" name="years[]" required
                    onChange={handleYearsChange} value={displayYear}
                  >
                    <option value="1">Certificate validity - 1 Year</option>
                    <option value="2">Certificate validity - 2 Years</option>
                    <option value="3">Certificate validity - 3 Years</option>
                    <option value="4">Certificate validity - 4 Years</option>
                    <option value="5">Certificate validity - 5 Years</option>
                  </select>
                </td>
                <td className="cell-style">
                  <span className="turnoverType hidden">Based on the selection of your inputs <h5 className="text-blue-900 font-bold text-base">FSSAI Basic Registration</h5> is applicable in your case</span>
                  <div className="fav">
                    <label></label>
                    <span className="text-black font-bold text-base">Applicable Price ₹{displayPrice}/-<br />Including Taxes & Fees</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="cell-style custom" colSpan="2" >Change required Certificate validity from the above dropdown.</td>
              </tr>
            </tbody>
          </table>
        }
        {selectedCapacityKey != '1' &&
          <div className="yearDisplay block">
            <table className="table-style">
              <tbody>
                <tr>
                  <td className="cell-style custom">
                    <span className="text-black font-bold text-lg pricing_displayx"></span>
                    <span className="customx">Default Certificate Validity : {displayYear} Year</span>
                  </td>
                  <td className="cell-style custom">
                    <span className="turnoverType hidden"></span>
                    <div className="fav">
                      <label></label>
                      <span className="text-black font-bold text-lg pricing_display">
                        Applicable Price ₹{displayPrice}/-
                        <br />
                        Including Taxes &amp; Fees
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        }
      </div>
    </div>
  );
};

export default FoodVendingAgencies;

