import React from 'react';

function PricingPage() {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Consultancy Pricing</h1>
        
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-300 py-2 px-4 text-left">Consultancy</th>
              <th className="border-b-2 border-gray-300 py-2 px-4 text-left">Price (INR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b border-gray-300 py-2 px-4">Food License Registration (1 year)</td>
              <td className="border-b border-gray-300 py-2 px-4">2,999</td>
            </tr>
            <tr>
              <td className="border-b border-gray-300 py-2 px-4">Food License Registration (2 year)</td>
              <td className="border-b border-gray-300 py-2 px-4">3,299</td>
            </tr>
            <tr>
              <td className="border-b border-gray-300 py-2 px-4">Food License Registration (3 year)</td>
              <td className="border-b border-gray-300 py-2 px-4">3,899</td>
            </tr>
            <tr>
              <td className="border-b border-gray-300 py-2 px-4">Food License Registration (4 year)</td>
              <td className="border-b border-gray-300 py-2 px-4">4,399</td>
            </tr>
            <tr>
              <td className="border-b border-gray-300 py-2 px-4">Food License Registration (5 year)</td>
              <td className="border-b border-gray-300 py-2 px-4">4,899</td>
            </tr>
          </tbody>
        </table>   
      </div>
    </div>
  );
}

export default PricingPage;
