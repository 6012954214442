import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const PaymentSuccess = () => {

  const handleGoHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-green-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-green-500">Payment Successful!</h1>
        <p className="mt-4 text-lg text-gray-700">
          Thank you for your payment. Your transaction was successful.
        </p>
        <p className="mt-4 text-lg text-gray-700">
          We will call back you shortly .
        </p>
        <p className="mt-4 text-lg text-gray-700">
          You can reach us to :
          <a href="tel:+918847728338" className=" hover:text-green-600">
            <FontAwesomeIcon icon={faPhone} /> Call +91-8847728338
          </a>
        </p>
        <p className="mt-4 text-lg text-gray-700  font-bold">
          Note: We Have 100% refund guarantee. 
        </p>

        <div className="mt-6">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600"
            onClick={handleGoHome}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
