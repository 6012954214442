import React from 'react';

function Footer() {
  return (
    <div className="border-black text-black " >
      <div className="mx-auto px-4" style={{ backgroundColor: "rgb(0, 36, 107)", color: "white" }}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pt-2">
          <div className="text-left">
            <p className="text-xl font-bold mb-4">Quick Links :</p>
            <hr className="border border-black mb-4" />
            <ul className="text-sm">
              <li className="py-2"><a href="/food-license-registration">Home</a></li>
              <li className="py-2"><a href="/about-us">About Us</a></li>
              <li className="py-2"><a href="/contact-us">Contact Us</a></li>
              <li className="py-2"><a href="/terms-of-service"> Terms &amp; Conditions</a></li>
              <li className="py-2"><a href="/privacy-policy">Privacy Policy</a></li>
              <li className="py-2"><a href="/warranties-and-refunds">Warranties &amp; Refunds</a></li>
              <li className="py-2"><a href="/disclaimer-policy">Disclaimer Policy</a></li>
              <li className="py-2"><a href="/data-security-policy">Data Security Policy</a></li>
              <li className="py-2"><a href="/refund-policy">Refund Policy</a></li>
              <li className="py-2"><a href="/grievance">Grievance Redressal Policy</a></li>        
              <li className="py-2"><a href="/pricing">Pricing</a></li>       
            </ul>
          </div>
          <div className="text-left">
            <p className="text-xl font-bold mb-4">Contacts :</p>
            <hr className="border border-black mb-4" />
            <span className="text-sm block">HELPLINE:</span>
            <span className="text-sm block mb-4">(+91) - 88477 28338</span>
            <span className="text-sm block">EMAIL:</span>
            <span className="text-sm block">unitedconsultancy051@gmail.com</span>
          </div>
          <div className="col-span-1 md:col-span-2 text-justify text-sm">
            <p>The owner is registered and approved as a Authorised Food Licence Application Filing Service Provider for Food Licensing Services under FSSAI.</p>
          </div>
        </div>
        <div className="text-center mt-8 text-xl mr-2">
          {/* <a href="https://www.facebook.com" className="text-xl mr-2" style={{color: 'black'}}> */}
          <i className="fab fa-facebook-f mr-1"></i> Foodlicense Online
          {/* </a> */}
          <p className="text-xs">&copy; Fsaai Registration. All Rights Reserved</p>
        </div>
      </div>
      <div className="mx-auto px-4 py-8">
        <div className="text-center">
          <p>foodlicenseonline.in 2024, All Rights Reserved</p>
          <ul className="list-none">
            <li className="my-4">
              <b>Website and Google ads are Managed By our firm only "Swap"</b>
            </li>
            <li>
              <a href="https://foodlicenseonline.in/disclaimer-policy" className="">
                <h5 className="text-lg font-bold">Disclaimer</h5>
                <p className="text-left">
                  1. foodlicenseonline.in is a platform that helps you hire/connect with experts based on the information form and details you provide, facilitating communication between experts and potential users.
                </p>
                <p className="text-left">
                  2. Please note that neither foodlicenseonline.in, nor any of its subsidiaries or employees are experts. We are not a firm that provides services related to government documents and services, and we do not provide any advice.
                </p>
                <p className="text-left">
                  3. All our experts are third-party individuals. Any fake or irrelevant comments made by these experts are not the responsibility of our company. (If there is any fake commitment or misinformation provided by any of our experts, please contact us. If it is found to be true, we will ban them from our portal.)
                </p>
                <p className="text-left">
                  4. The 'FOOD LICENCE REGISTRATION FORM'  available on this website are not registration forms or official government forms for the registration and renewal of food licenses or FSSAI licenses. These forms have been created by our private firm for the sole purpose of gathering user information regarding the requirement for a new food license and FSSAI renewal for our business operations and aiding experts in understanding users' requirements for a 'New Food License' or renewing an 'FSSAI License' with the information users provide in the Information Form.
                </p>
                <p className="text-left">
                  5. Our business model is to connect users or businesses to the right and reliable experts and facilitate communication between experts and potential users.
                </p>
                <p className="text-left">
                  6. The word "Registration" is the name of a food business license. However, this does not imply that we help users register for a food license, as we are a platform for accessing experts only.
                </p>
                <p className="text-left">
                  7. The information on this website is for knowledge purposes only and should not be relied upon as legal advice or opinion.
                </p>
                <p className="text-left">
                  8. Please note that our website is only for hiring/connecting with third-party experts and facilitating communication between experts and potential users. We do not provide any guidance, advice, or services related to food business and its compliances like food licenses and other technicalities.
                </p>
                <p className="text-left">
                  9. This is not a government-run website. By proceeding with this website, you acknowledge that we are a private company managing this website and providing a service for hiring specialists based on customer requests.
                </p>
                <p className="text-blue-500 underline mt-2">Click to read the full disclaimer</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
}

export default Footer;
