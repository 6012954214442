import React, { useState, useEffect } from 'react';
import config from './config';

// import { FontAwesomeIcon } from 'react-icons/fa'; // Import the filled icon

// import icon1 from './assest/icon/icon1.png';
// import icon2 from './assest/icon/icon2.png';
// import icon3 from './assest/icon/icon3.png';

const backendServerUrl = config.backendServerUrl;
function ViewFoodRegistration() {
    const [formData, setFormData] = useState({
        application_type: '',
        name_of_company: '',
        email_id: '',
        mobile_no: '',
        nature_of_business: '',
        food_category: '',
        designation: '',
        house_no: '',
        area: '',
        city: '',
        district: '',
        state: '',
        pin: '',
        no_of_years: 0,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // setUserId(localStorage.getItem("user_id"));
        // localStorage.getItem('isLoggedIn');
        // if (localStorage.getItem('isLoggedIn') != 'true') {
        //     window.location.href = 'login'
        // }
        // searchReport();
        const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {

            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.dataset.payment_button_id = "pl_O0nYoxyUQzkTiL";
            rzpPaymentForm.appendChild(script);
        }

    }, []);

    useEffect(() => {

        showForm(getParameter('id'));
        // searchReport();
    }, []);

    const showForm = (id) => {
        let postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                form_id: id,
            })
        };

        fetch(`${backendServerUrl}/api/show_form`, postData)
            .then(
                res => (res.json())
            )
            .then(
                (res) => {

                    console.log(res.data[0]);
                    const newData = res.data[0];
                    setFormData(newData);

                    console.log(formData);

                }
            );
    };

    const getParameter = (param) => {
        var urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }


    return (
        <div>
            <section className="relative bg-white" id="unique-section-id">
                <div className="container mx-auto">

                    <div className="row">
                        <div className="col-md-6 top-15 pt-5" id="applyForm">
                            <div className="col-md-12 regi-panel2 div-shadow-box bord-rad top-20">
                                <div className="col-md-12 registration-title border-none font-semibold mb-1 text-center">Please Re-Verify Details</div>
                            </div>
                            {/* <div className="clb"></div> */}
                            <div className="col-md-12 form-bg2 div-shadow-box">
                                <div className=''>
                                    <form >
                                        <div className="row flex flex-col space-y-4">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Application Type<font color="red">*</font></label>
                                                    <select
                                                        name="application_type"
                                                        id="ddlATType"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        value={formData.application_type}
                                                        disabled
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Registration">Registration</option>
                                                        <option value="Modification">Modification</option>
                                                        <option value="Cancellation">Cancellation</option>
                                                    </select>
                                                    {errors.application_type && <span className="text-red-500">{errors.application_type}</span>}

                                                    {/* {applicationTypeError && (
                                                    <p className="text-red-500">{applicationTypeError}</p>
                                                )} */}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Name of Applicant / Company <span className="text-red-500">*</span></label>
                                                    <input name="name_of_company" value={formData.name_of_company}
                                                        type="text"
                                                        id="txtName"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Name of Applicant / Company"
                                                        required
                                                        disabled
                                                    />
                                                    {errors.name_of_company && <span className="text-red-500">{errors.name_of_company}</span>}

                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Email Id <span className="text-red-500">*</span></label>
                                                    <input value={formData.email_id}
                                                        name="email_id"
                                                        type="text"
                                                        maxLength="100"
                                                        id="txtEmail"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Email Id"
                                                        required
                                                        disabled
                                                    />
                                                    {errors.email_id && <span className="text-red-500">{errors.email_id}</span>}


                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Mobile No <span className="text-red-500">*</span></label>
                                                    <input value={formData.mobile_no}
                                                        name="mobile_no"
                                                        type="text"
                                                        id="txtPhone1"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="98XXXXXXXX (Without 0 or +91)"
                                                        title="Enter valid 10 digits mobile number"
                                                        required
                                                        disabled
                                                    />
                                                    {errors.mobile_no && <span className="text-red-500">{errors.mobile_no}</span>}

                                                </div>
                                            </div>
                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Nature of Business</label>
                                                    <select value={formData.nature_of_business}
                                                        disabled
                                                        name="nature_of_business"
                                                        id="ddlNatureBusiness"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Manufacturer">Manufacturer</option>
                                                        <option value="Importers">Importers</option>
                                                        <option value="Exporters">Exporters</option>
                                                        <option value="Cold Storage">Cold Storage</option>
                                                        <option value="Wholesaler">Wholesaler</option>
                                                        <option value="Retailer">Retailer</option>
                                                        <option value="Distributor">Distributor</option>
                                                        <option value="Food Vending Agency">Food Vending Agency</option>
                                                        <option value="Supplier">Supplier</option>
                                                        <option value="Caterer">Caterer</option>
                                                        <option value="Dhaba, Boarding Houses, Banquet Halls, Home Based Canteens, Dabba Wala, Food Stalls">Dhaba, Boarding Houses, Banquet Halls, Home Based Canteens, Dabba Wala, Food Stalls</option>
                                                        <option value="Club/Canteen">Club/Canteen</option>
                                                        <option value="Hotel">Hotel</option>
                                                        <option value="Restaurant">Restaurant</option>
                                                        <option value="Transporter">Transporter</option>
                                                        <option value="Marketer">Marketer</option>
                                                        <option value="Hawker">Hawker</option>
                                                        <option value="Petty Retailers of Snacks/ tea shops">Petty Retailers of Snacks/ tea shops</option>
                                                    </select>
                                                    {errors.nature_of_business && <span className="text-red-500">{errors.nature_of_business}</span>}

                                                </div>
                                            </div>


                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Name of the Food Category </label>
                                                    <select value={formData.food_category}
                                                        name="food_category"
                                                        disabled
                                                        id="ddlFoodCategory"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Dairy products and analogues, excluding products of food category 2.0">Dairy products and analogues, excluding products of food category 2.0</option>
                                                        <option value="Fats and oils, and fat emulsions">Fats and oils, and fat emulsions</option>
                                                        <option value="Edible ices, including sherbet and sorbe">Edible ices, including sherbet and sorbe</option>
                                                        <option value="Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera), seaweeds, and nuts and seeds">Fruits and vegetables (including mushrooms and fungi, roots and tubers, pulses and legumes, and aloe vera), seaweeds, and nuts and seeds</option>
                                                        <option value="Confectionery">Confectionery</option>
                                                        <option value="Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes and pith or soft core of palm tree, excluding bakery wares of food category 7.0">Cereals and cereal products, derived from cereal grains, from roots and tubers, pulses, legumes and pith or soft core of palm tree, excluding bakery wares of food category 7.0</option>
                                                        <option value="Bakery products">Bakery products</option>
                                                        <option value="Meat and meat products including poultry">Meat and meat products including poultry</option>
                                                        <option value="Fish and fish products, including molluscs, crustaceans, and echinoderms">Fish and fish products, including molluscs, crustaceans, and echinoderms</option>
                                                        <option value="Eggs and egg products">Eggs and egg products</option>
                                                        <option value="Sweeteners, including honey">Sweeteners, including honey</option>
                                                        <option value="Salts, spices, soups, sauces, salads and protein products">Salts, spices, soups, sauces, salads and protein products</option>
                                                        <option value="Foodstuffs intended for particular nutritional uses">Foodstuffs intended for particular nutritional uses</option>
                                                        <option value="Beverages, excluding dairy products">Beverages, excluding dairy products</option>
                                                        <option value="Ready-to-eat savouries">Ready-to-eat savouries</option>
                                                        <option value="Prepared Foods">Prepared Foods</option>
                                                        <option value="Substances added to food">Substances added to food</option>
                                                    </select>
                                                    {errors.food_category && <span className="text-red-500">{errors.food_category}</span>}

                                                </div>
                                            </div>
                                            {/* <div className="clb"></div> */}

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Select Designation</label>
                                                    <select value={formData.designation}
                                                        name="designation"
                                                        id="ddlDesignition"
                                                        disabled
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Select Designation</option>
                                                        <option value="INDIVIDUAL">INDIVIDUAL</option>
                                                        <option value="PARTNER">PARTNER</option>
                                                        <option value="PROPRIETOR">PROPRIETOR</option>
                                                        <option value="OTHERS">OTHERS</option>
                                                        <option value="SOCIETY">SOCIETY</option>
                                                        <option value="PVT LTD/LLP/OPC">PVT LTD/LLP/OPC</option>
                                                    </select>
                                                    {errors.designation && <span className="text-red-500">{errors.designation}</span>}

                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Address</label>
                                                </div>
                                            </div>
                                            <div className='grid gap-4 grid-cols-2'>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.house_no}
                                                            disabled
                                                            name="house_no"
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtHouse"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="HOUSE NO/SHOP NO/PLOT NO"
                                                        />
                                                        {errors.house_no && <span className="text-red-500">{errors.house_no}</span>}

                                                    </div>
                                                </div>
                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.area}
                                                            name="area"
                                                            disabled
                                                            type="text"
                                                            maxLength="200"
                                                            id="txtAreaLocality"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Area / Locality"
                                                        />
                                                        {errors.area && <span className="text-red-500">{errors.area}</span>}

                                                    </div>
                                                </div>
                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.city}
                                                            name="city"
                                                            type="text"
                                                            maxLength="200"
                                                            disabled
                                                            id="txtCity"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="City"
                                                        />
                                                        {errors.city && <span className="text-red-500">{errors.city}</span>}

                                                    </div>
                                                </div>

                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input value={formData.district}
                                                            name="district"
                                                            type="text"
                                                            maxLength="200"
                                                            disabled
                                                            id="txtDistrict"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="District"
                                                        />
                                                        {errors.district && <span className="text-red-500">{errors.district}</span>}

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <select value={formData.state}
                                                            name="state"
                                                            id="ddlState"
                                                            className="border border-gray-300 text-gray-300 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            required=""
                                                            disabled
                                                        >
                                                            <option value="">Select State</option>
                                                            <option value="ANDAMAN AND NICOBAR ISLANDS">ANDAMAN AND NICOBAR ISLANDS</option>
                                                            <option value="ANDHRA PRADESH">ANDHRA PRADESH</option>
                                                            <option value="ARUNACHAL PRADESH">ARUNACHAL PRADESH</option>
                                                            <option value="ASSAM">ASSAM</option>
                                                            <option value="BIHAR">BIHAR</option>
                                                            <option value="CHANDIGARH">CHANDIGARH</option>
                                                            <option value="CHHATISGARH">CHHATISGARH</option>
                                                            <option value="DADRA AND NAGAR HAVELI">DADRA AND NAGAR HAVELI</option>
                                                            <option value="DAMAN AND DIU">DAMAN AND DIU</option>
                                                            <option value="DELHI">DELHI</option>
                                                            <option value="GOA">GOA</option>
                                                            <option value="GUJARAT">GUJARAT</option>
                                                            <option value="HARYANA">HARYANA</option>
                                                            <option value="HIMACHAL PRADESH">HIMACHAL PRADESH</option>
                                                            <option value="JAMMU AND KASHMIR">JAMMU AND KASHMIR</option>
                                                            <option value="JHARKHAND">JHARKHAND</option>
                                                            <option value="KARNATAKA">KARNATAKA</option>
                                                            <option value="KERALA">KERALA</option>
                                                            <option value="LAKSHADWEEP">LAKSHADWEEP</option>
                                                            <option value="MADHYA PRADESH">MADHYA PRADESH</option>
                                                            <option value="MAHARASHTRA">MAHARASHTRA</option>
                                                            <option value="MANIPUR">MANIPUR</option>
                                                            <option value="MEGHALAYA">MEGHALAYA</option>
                                                            <option value="MIZORAM">MIZORAM</option>
                                                            <option value="NAGALAND">NAGALAND</option>
                                                            <option value="ODISHA">ODISHA</option>
                                                            <option value="PONDICHERRY">PONDICHERRY</option>
                                                            <option value="PUNJAB">PUNJAB</option>
                                                            <option value="RAJASTHAN">RAJASTHAN</option>
                                                            <option value="SIKKIM">SIKKIM</option>
                                                            <option value="TAMIL NADU">TAMIL NADU</option>
                                                            <option value="TELANGANA">TELANGANA</option>
                                                            <option value="TRIPURA">TRIPURA</option>
                                                            <option value="UTTAR PRADESH">UTTAR PRADESH</option>
                                                            <option value="UTTARAKHAND">UTTARAKHAND</option>
                                                            <option value="WEST BENGAL">WEST BENGAL</option>
                                                        </select>
                                                        {errors.state && <span className="text-red-500">{errors.state}</span>}

                                                    </div>
                                                </div>
                                                {/* <div className="clb"></div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        {/* <label className="font-semibold mb-1">Pin</label> */}
                                                        <input value={formData.pin}
                                                            name="pin"
                                                            type="text"
                                                            maxLength="6"
                                                            id="txtPin"
                                                            className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="PIN"
                                                            disabled
                                                        />
                                                        {errors.pin && <span className="text-red-500">{errors.pin}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="clb"></div> */}


                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="font-semibold mb-1">Number of Years <span className="text-red-500">*</span></label>
                                                    <select value={formData.no_of_years}
                                                        name="no_of_years"
                                                        id="ddlyears"
                                                        className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        required=""
                                                        disabled
                                                    >
                                                        <option value="0">Select Number of Years</option>
                                                        <option value="1">1 Year</option>
                                                        <option value="2">2 Years</option>
                                                        <option value="3">3 Years</option>
                                                        <option value="4">4 Years</option>
                                                        <option value="5">5 Years</option>
                                                    </select>
                                                    {errors.no_of_years && <span className="text-red-500">{errors.no_of_years}</span>}

                                                </div>
                                            </div>

                                            {/* <div className="clb"></div> */}
                                            <div className="col-md-12 top-15">
                                                <p>
                                                    I hereby declare that information provided in the online registration form is true to the best of my knowledge and I accept terms, disclaimer, and policies by clicking "Submit & Pay" button.
                                                </p>
                                                <div>
                                                    <form id="rzp_payment_form"></form>
                                                </div>
                                                {/* <div className="form-group">
                                                <span id="lblStatus"></span>
                                                <input
                                                    type="submit"
                                                    name="BtnSubmitRegistraton"
                                                    value="submit"
                                                    onClick="return CheckSelect();"
                                                    id="BtnSubmitRegistraton"
                                                    className="btn btn-send w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                                />
                                            </div> */}
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ViewFoodRegistration;