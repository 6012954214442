import React, { useState, useEffect } from 'react';

const DefaultPricing = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const price = {
    '1': 700,
    '2': 1400,
    '3': 2100,
    '4': 2800,
    '5': 3500,
  };

  const handleYearChange = (event) => {
    setSelectedCapacityKey(event.target.value);
    setSelection('');

    setSelectedYears(event.target.value);
    setSelectedPrice(price[event.target.value]);

  };

  useEffect(() => {
    setSelection('');

    setSelectedYears(1);
    setSelectedPrice(price[1]);

  }, []);

  return (
    <div className="turnoverDisplay">
      <div className="container bg-white p-3 text-justify mb-3">
        <h6 className="font-bold">Proprietary Food:</h6>
        <span>Proprietary Foods are those which are not standardized under regulations and may contain primary ingredients and some generic additives.</span>
      </div>

      {/* Hidden section with radio button for turnover selection */}
      <div className="hidden">
        <label className="font-bold" id="exp">Select Turnover <span className="text-red-600">*</span></label>
        <div className="form-group">
          <input
            type="radio"
            name="turnover"

            value="Turnover Above 20CR"
          />
          <label className="custom">Turnover Above 20CR</label>
          <div id="vegi1" style={{ fontSize: '12px' }}></div>
        </div>
      </div>

      {/* Table section */}
      <table className="table-style">
        <tbody>
          <tr>
            <td className="cell-style">
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">Select Validity</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  Applicable Price Including Taxes & Fees
                </span>
              </div>
            </td> */}
          </tr>
          <tr>
            <td className="cell-style">
              <input
                type="radio"
                name="turnover"
                required
                value="1"
                checked={selectedCapacityKey == '1'}
                onChange={handleYearChange}
                className='mr-2'
              />
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">1 Year</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  ₹700/-
                </span>
              </div>
            </td> */}
          </tr>
          <tr>
            <td className="cell-style">
              <input
                type="radio"
                name="turnover"
                required
                value="2"
                checked={selectedCapacityKey == '2'}
                onChange={handleYearChange}
                className='mr-2'
              />
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">2 Year</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  ₹1400/-
                </span>
              </div>
            </td> */}
          </tr>
          <tr>
            <td className="cell-style">
              <input
                type="radio"
                name="turnover"
                required
                value="3"
                checked={selectedCapacityKey == '3'}
                onChange={handleYearChange}
                className='mr-2'
              />
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">3 Year</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  ₹2100/-
                </span>
              </div>
            </td> */}
          </tr>
          <tr>
            <td className="cell-style">
              <input
                type="radio"
                name="turnover"
                required
                value="4"
                checked={selectedCapacityKey == '4'}
                onChange={handleYearChange}
                className='mr-2'
              />
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">4 Year</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  ₹2800/-
                </span>
              </div>
            </td> */}
          </tr>
          <tr>
            <td className="cell-style">
              <input
                type="radio"
                name="turnover"
                required
                value="5"
                checked={selectedCapacityKey == '5'}
                onChange={handleYearChange}
                className='mr-2'
              />
              <span className="text-black font-bold text-lg pricing_display"></span>
              <span className="text-black font-bold text-lg pricing_display">5 Year</span>
            </td>
            {/* <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">
                  ₹3500/-
                </span>
              </div>
            </td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DefaultPricing;
