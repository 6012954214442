import React from 'react';

const DisclaimerPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Disclaimer Policy</h1>
      <p className="mb-4">
        Please read this document carefully before accessing or using the site. By accessing or using the site, you agree to be bound by the terms and conditions set forth below. If you do not wish to be bound by these terms and conditions, you may not access or use the site. foodlicenseonline.in may modify this agreement at any time, and such modifications shall be effective immediately upon posting of the modified agreement on the site. You agree to review the agreement periodically to be aware of such modifications and your continued access or use of the site shall be deemed your conclusive acceptance of the modified agreement.
      </p>
      <h2 className="text-2xl font-bold mb-2">1. Use Of The Site</h2>
      <p className="mb-4">
        You understand that, except for information, products or services clearly identified as being supplied by foodlicenseonline.in, we do not operate, control or endorse any information, products or services on the internet in any way.
      </p>
      <p className="mb-4">
        You also understand that foodlicenseonline.in cannot and does not guarantee or warrant that files available for downloading through the site will be free of infection or viruses, worms, trojan horses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to the site for the reconstruction of any lost data.
      </p>
      <p className="mb-4">
        You assume total responsibility and risk for your use of the site and the internet. foodlicenseonline.in provides the site and related information "As is" and does not make any express or implied warranties, representations or endorsements whatsoever (including without limitation warranties of title or noninfringement, or the implied warranties of merchantability or fitness for a particular purpose) with regard to the service, any merchandise information or service provided through the service or on the internet generally, and foodlicenseonline.in shall not be liable for any cost or damage arising either directly or indirectly from any such transaction. It is solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other information provided through the service or on the internet generally. foodlicenseonline.in does not warrant that the service will be uninterrupted or error-free or that defects in the service will be corrected. You understand further that the pure nature of the internet contains unedited materials some of which are sexually explicit or may be offensive to you. Your access to such materials is at your risk. foodlicenseonline.in has no control over and accepts no responsibility whatsoever for such materials.
      </p>
      <h2 className="text-2xl font-bold mb-2">2. Limitation Of Liability</h2>
      <p className="mb-4">
        In no event will foodlicenseonline.in be liable for (I) any incidental, consequential, or indirect damages (including, but not limited to, damages for loss of profits, business interruption, loss of programs or information, and the like) arising out of the use of or inability to use the service, or any information, or transactions provided on the service, or downloaded from the service, or any delay of such information or service. Even if foodlicenseonline.in or its authorized representatives have been advised of the possibility of such damages, or (ii) any claim attributable to errors, omissions, or other inaccuracies in the service and/or materials or information downloaded through the service. Because some states do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. In such states, foodlicenseonline.in liability is limited to the greatest extent permitted by law.
      </p>
      <h2 className="text-2xl font-bold mb-2">3. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify, defend and hold harmless foodlicenseonline.in, its officers, employees and any third party information providers to the service from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this agreement (including negligent or wrongful conduct) by you or any other person accessing the service.
      </p>
      <h2 className="text-2xl font-bold mb-2">4. Miscellaneous</h2>
      <p className="mb-4">
        This agreement shall all be governed and construed in accordance with the laws of India applicable to agreements made and to be performed in India. You agree that any legal action or proceeding between foodlicenseonline.in and you for any purpose concerning this agreement or the parties' obligations hereunder shall be brought exclusively in a federal or state court of competent jurisdiction sitting in India. Any cause of action or claim you may have with respect to the service must be commenced within one (1) year after the claim or cause of action arises or such claim or cause of action is barred.
      </p>
    </div>
  );
};

export default DisclaimerPolicy;
